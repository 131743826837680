@mixin full-width(){
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@mixin font-style($font:'',$line_h:'',$letter:'',$weight:'',$color:''){

  @if $font!=''{
    font-size: $font;
  }

  @if $line_h != ''{
    line-height: $line_h;
  }

  @if $letter != ''{
    letter-spacing: $letter;
  }

  @if $weight != ''{
    font-weight: $weight;
  }
  @if $color != ''{
    color: $color;
  }
}

@mixin text-clamp($clamp){
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:$clamp;
}