@import "../vars";
@import "../mixin";

.main-inner{
  padding-left: 0;
  padding-right: 0;
}
 .pane-title{
  text-align: center;
  margin: 1em 0 0;
  @include font-style(28px,45px,.05em,900,#000)
}
 .subtitle{
  text-align: center;
  margin-bottom: 2em;
}

.pane-content{
  @include font-style(17px,28px,.1em);
}

/*-- 搜尋區塊  --*/
.pane-front-search{
  padding: 60px 0;
  background: url(../../images/custom/bg--section-explore.jpg) 50% 50% no-repeat;
  background-size: cover;
  position: relative;

  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%);
  }

  .search-bar-title{
    text-align: left;
    font-size: 40px;
    line-height: 58px;
    letter-spacing: 0.05em;
  }
  .search-bar-desc{
    text-align: left;
  }
  .pane-content {
    text-align: center;
    color: #fff;
    max-width: 1000px;
  }
  .container-inline{
    display: flex;
    position: relative;
    align-items: center;

    >div{
      display: block;
    }
  }
  .form-type-textfield{
    flex: 0 0 calc(100% - 70px);

  }
  input[type="submit"]{
    margin-left: 10px;
    //position: absolute;
    //background: transparent;
    //text-indent: -9999px;
    //overflow: hidden;
    //top: 0;

  }
  #edit-submit{
    //background: url(../../images/custom/icon--search-dark.png) no-repeat center;
    //background-size: 20px 20px;
  }

  
  @media all and (min-width: 1200px) {
    padding: 160px 0;

  }
}
#autocomplete{
  display: block;
  text-align: left;
  li{
    white-space: normal;
    div{
      display: block;
    }
  }
  @media all and (min-width: 1200px) {

  }
}

/*-- 關於臺灣生命大百科  --*/
.pane-eol-muse-eol-muse-front-overview{
  .pane-content{
    max-width: 1080px;
    margin: 0 auto;
    padding: 64px 1em;
  }
}

.front-about-1{
  .about-body{
    font-size: 17px;
    max-width: 653px;
    padding-right: 1em;
    letter-spacing: 0.1em;
  }
  .about-1-links{
    margin-top: 3em;
    font-size: 13px;
  }
  .about-1-links >div{
    display: inline-block;
    margin-right: 1em;
  }
  .about-cover{
    flex: 0 0 300px;
    position: relative;
  }
}
.about-1-links{
  img,span{
    display: inline-block;
    vertical-align: bottom;
    margin: 0 0.5em;
  }
}
.about-cover .counter{
  position: absolute;
  font-size: 55px;
  line-height: 80px;
  font-weight: 900;
  color: #B3D100;
  bottom: 6%;
  right: 0;
}

@media all and (min-width: 1024px){
  .front-about-1{
    display: flex;
    justify-content: space-between;
  }
}

/*-- 探索熱門臺灣物種  --*/
.pane-obj-list-1{
  background-color: #f4f2ef;
  padding: 1em 0;
}
.pane-obj-list-1 .pane-content{
}
.pane-obj-list-1 .view-content{
  display: flex;
}

.kanban7{
  .kanban-title{
    text-shadow: none;
  }
  .card-cover{
    a{
      display: block;
      position: relative;
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        border-radius: 5px;
        opacity: 0.6;
        transition: opacity 0.3s;
      }
    }
  }
  .card-title a{
    color: #7c7c7c;
    transition: color 0.3s;
  }


  .tns-nav{
    margin-top: 25px;
    button{
      background-color: #AECF02;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      &.tns-nav-active{
        background-color: transparent;
        border: 1px solid #AECF02;
      }
    }
  }
  .tns-controls{
    top:calc((100% -  53px)/2);
    button{
      color: #fff;
      width: 40px;
      height: 40px;
      font-size: 22px;
      border-radius: 5em;
      background-color: #c3d600;
    }
    button[data-controls='prev']{
      left: 30px;
    }
    button[data-controls='next']{
      right: 30px;
    }
  }
  @media all and (min-width: 1200px) {
    .card-cover a:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #000;
      border-radius: 5px;
      opacity: 0.6;
      transition: opacity 0.3s;
    }
    .card-inner:hover{
      .card-cover a:after{
        opacity: 0;
      }
      .card-title a{
        color: #fff;
      }
    }

    .tns-nav{
      margin-top: 55px;
    }
    .tns-controls{
      top:calc((100% -  81px)/2);
    }
  }
}

@media all and (min-width: 1024px){
  .pane-obj-list-1{
    padding: 1em 0 5em;
  }
}


/*-- 臺灣的物種  --*/
.pane-eol-muse-eol-muse-front-taxon{
  padding: 1em 1.5em 3em;
  background: url(../../images/home/bg--front-cata.png) no-repeat center bottom ,linear-gradient(0deg, rgba(178, 209, 13, 0.26) 0%, rgba(178, 209, 13, 0) 50%);
  background-size: cover;

  .pane-content{

  }
  .view{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  .entity-row{
    padding-left: 10px;
    padding-right: 10px;
  }
  .entity-row-inner{
    display: flex;
    position: relative;
    align-items: stretch;
    background:linear-gradient(180deg, #C3D600 0%, #B3D100 54.17%, #9BC815 100%) ;
    border-radius: 5px;
  }
  .entity-list-content{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .entity-list-title{
    color: #fff;
  }
  .entity-list-img{
    width: 105px;
  }
  .entity-list-cover{
    position: relative;
  }
  .entity-list-counter{
    position: absolute;
    top: 8px;
    left: -8px;
    font-size: 13px;
    line-height: 1.25;
    border-radius: 3em;
    background-color: #AECF02;
    padding: 0 0.5em;
    &:after{
      content: '+';
    }
  }

  @media all and (min-width: 1024px){
    padding: 1em 0 5em;

    .entity-list-img{
    }
  }
}


/*-- 關於臺灣生命大百科  --*/
.pane-eol-muse-eol-muse-front-about{
  background: linear-gradient(180deg, #C3D600 0%, #B3D100 54.17%, #9BC815 100%) ;
  padding: 2em 0;
  .pane-content{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  @media all and (min-width: 768px) {
    background: url(../../images/home/bg--front-about2.jpg) no-repeat top left calc((100vw - 1200px) / 2),linear-gradient(180deg, #C3D600 0%, #B3D100 54.17%, #9BC815 100%) ;
    background-size: contain;
    padding: 1em 0 5em;
  }
}

.front-about-2{
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;

  .about-body{

    text-align: left;
    font-size: 17px;
    h4{
      font-size: 22px;
      letter-spacing: 0.1em;
      font-weight: 700;
      margin-bottom: 0.8em;
    }
  }
  .about-2-links{
    display: flex;
    justify-content: space-between;
    margin-top: 1.5em;

    img{
      mix-blend-mode: multiply;
      margin-right: 5px;
      width: 34px;
    }
  }
  @media all and (min-width: 768px) {
    .about-body{
      width: 49.3%;
    }
  }
}

@media all and (min-width: 1024px){
  .pane-eol-muse-eol-muse-front-about{
    background-size: contain;
    padding: 1em 0 5em;
    .about-body{
      width: 56.3%;
      margin:  2em 0 0;
    }
  }
  .front-about-2{
    padding: 0;
  }
}

/*-- 參與我們  --*/
.pane-eol-muse-eol-muse-front-join{
  .pane-content{
    position: relative;
    padding: 3.5em 1em;
    &::before{
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url(../../images/home/bg--join.png) no-repeat center;
      opacity: 0.6;
      background-size: cover;
    }
  }
  .front-cta h3{
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0.1em;
    margin-bottom: 1em;
  }
  .btn, .btn:hover{
    background: linear-gradient(180deg, #C3D600 0%, #B3D100 54.17%, #9BC815 100%);
    border-radius: 57px;
    color: #000;
    font-weight: bold;
    width: 200px;
    height: 52px;
    //line-height:34px ;
    @include font-style(17px,34px,0.08em);
    text-align: center;
    display: inline-block;
  }
  .actions{
    text-align: center;
  }
}


/*-- 公告訊息  --*/
.pane-news-list{
  padding: 1em 0 3em;
  .pane-title{
    margin-bottom: 1em;
  }
  .pane-content{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
  }
  .entity-row-inner{
    background-color:#f4f2ef ;
    border-radius: 5px;
    padding: 1.5em;
  }
  .entity-list-date{
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.08em;
    color: #aecf02;
  }
  .entity-list-title{
    font-size: 17px;
    letter-spacing: 0.1em;
  }
  .more-link{
    text-align: center;
    .btn,.btn:hover{
      background-color: transparent;
      color:#aecf02 ;
      border:2px solid #aecf02;
      border-radius: 3em;
      display: inline-block;
      width: 190px;
      line-height: 1.75em;
    }
  }
  @media all and (min-width: 1024px) {

    .pane-content{
      padding: 0;
    }
  }
}

/*-- 分享按鈕  --*/
.pane-content-footer-share{
  text-align: center;
  background-color: #F4F2EF;
  padding: 18px 0 22px;
  .channel-fb{
    color: #000;
    @include font-style(15px,18px,.05em);
    img{
      width: 18px;
      margin-right: 5px;
      border-radius: 3px;
    }
  }
}




